<template>
	<div v-if="formationProgress">
		<v-row
			class="fullAssistantContainer"
			:class="{
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<div class="ortho-text-blue mb5 main-assistant-header-container">
				<div class="header-div-name">
					<h3
						v-if="checking.length == 0"
						class="assistant-link"
						@click="showAssistant = true"
						:class="{
							menuSelected: showAssistant == true,
						}"
					>
						🔎 Vérifie et améliore ton texte
					</h3>
					<div v-if="checking.length != 0" class="ortho-text-blue assistant-container">
						<img src="@/assets/icons/assistant.svg" class="assistant-icon" alt="" />
						<h3 class="ortho-text-gray">{{ checking.length }} suggestions</h3>
					</div>
					<h3
						class="ortho-text-gray assistant-link ml-6 d-none"
						@click="showAssistant = false"
						:class="{
							menuSelected: showAssistant == false,
						}"
					>
						Mes enregistrements
					</h3>
				</div>
				<div v-if="checking.length != 0" class="assistant-container">
					<div>
						<div class="score-container text-center">
							<div class="score-display">
								<div class="progress-bar-container">
									<div class="score-index">
										<span class="score-value">{{ calculateScore }}%</span>
									</div>
									<div class="progress-gradient">
										<div class="progress-marker" :style="{ left: `${calculateScore}%` }"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-if="showAssistant == true"
		>
			<v-col md="8" cols="12">
				<div class="container-textarea">
					<v-card class="shadow-blue-not-openned mb-5">
						<v-container fluid>
							<div
								contenteditable="true"
								class="divEditionText hide-scroll-bar fs14"
								rows="15"
								v-html="text"
								ref="text"
								style="overflow: scroll"
								@input="startAutoCheck"
								@paste.prevent="handlePaste"
							>
								{{ text }}
							</div>
						</v-container>
						<p v-if="showConfirmation == true" class="mb-0 mr-4 ortho-text-green text-right">Texte enregistré !</p>
						<v-container
							fluid
							class="d-flex"
							:class="{
								'justify-end': checking.length != 0,
								'justify-end align-left': checking.length == 0,
							}"
						>
							<v-btn v-show="false" large depressed class="shades white black--text mr-1" @click="registerText()"
								>Enregistrer mon texte</v-btn
							>
							<!-- <star-rating
								class="mr-3 ratingStars"
								v-model="rating"
								:star-size="30"
								:show-rating="false"
								v-if="checking.length != 0 && showRating == true"
								@rating-selected="getRating()"
							/> -->
							<div v-if="showRating == false">
								<v-alert dense outlined type="success" color="#15c39a" class="mb-0 mr-1"
									>Ton avis a bien été envoyé, merci</v-alert
								>
							</div>
							<div class="actionBtnContainer">
								<v-btn
									v-if="text && text.trim()"
									icon
									class="mr-2"
									@click="cleanContent"
									:disabled="loading"
									color="error"
									title="Effacer le texte"
								>
									<v-icon>mdi-delete-outline</v-icon>
								</v-btn>
								<v-btn
									v-if="formationProgress.user.entreprise_id.slug == 'demo' || btnExample"
									class="actionBtnAssistant mr-2"
									depressed
									large
									dark
									color="black"
									:loading="loading"
									@click="submitExample()"
									>Montre moi un exemple</v-btn
								>
								<v-btn
									class="actionBtnAssistant mr-2"
									depressed
									large
									dark
									:color="autoCorrectEnabled ? '#000000' : '#12c399'"
									:loading="loading"
									@click="toggleAutoCorrect"
								>
									<v-icon v-if="autoCorrectEnabled" left>mdi-clock-outline</v-icon>
									<v-icon v-else left>mdi-magnify</v-icon>
									{{ buttonText }}
								</v-btn>
								<v-btn
									v-if="reformulatedBtn"
									class="actionBtnAssistant"
									depressed
									large
									dark
									outlined
									color="#7854f7"
									:loading="loadingReformulation"
									@click="reformulateText()"
								>
									Corriger et reformuler
								</v-btn>
							</div>
						</v-container>
					</v-card>
					<v-card
						v-if="reformulatedText"
						class="shadow-blue-not-openned"
						style="border: 1px solid #7854f7; background-color: #e9edff"
					>
						<v-container fluid>
							<h3 class="mb-5" style="color: #7854f7">✨ Proposition de reformulation</h3>
							<div class="fs14">
								<p>{{ reformulatedText }}</p>
							</div>
							<div
								fluid
								class="d-flex mt-5"
								:class="{
									'justify-end': checking.length != 0,
									'justify-end align-left': checking.length == 0,
								}"
							>
								<v-btn
									class="actionBtnAssistant"
									icon
									color="#7854f7"
									:loading="loadingReformulation"
									@click="copyToClipboard(reformulatedText)"
								>
									<v-icon dark> mdi-content-copy </v-icon>
								</v-btn>
							</div>
							<v-snackbar v-model="snackbarCopied" left color="#7854f7" :timeout="3000">
								Texte copié dans le presse-papier !
							</v-snackbar>
						</v-container>
					</v-card>

					<v-card
						color="#7854f7"
						v-if="!reformulatedText && sectionReformulation == true"
						class="shadow-lg d-flex flex-column align-center justify-center"
						style="padding: 24px"
					>
						<v-container fluid class="text-center">
							<h3 class="white--text mb-5 font-weight-bold" style="font-size: 1.5rem">
								🚀 <span style="text-decoration: underline">Reformule</span> ton écriture avec style !
							</h3>
							<p class="white--text fs16" style="margin-bottom: 1.5rem">
								Transforme tes textes en un clin d'œil grâce à notre outil de reformulation intelligent.<br />
							</p>
							<p class="white--text fs16" style="margin-bottom: 1.5rem">
								<strong>Saisis ton texte ci-dessus et clique sur 'REFORMULER'</strong>
							</p>
						</v-container>
					</v-card>
				</div>
			</v-col>
			<v-col md="4" cols="12" :class="{ mt20: $vuetify.breakpoint.smAndDown }">
				<div v-if="loading" class="suggestions-skeleton">
					<v-card class="shadow-blue-not-openned mb20" v-for="i in 3" :key="'skeleton-' + i">
						<div class="pa-4">
							<v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
							<v-skeleton-loader class="mx-auto mt-4" type="chip, chip"></v-skeleton-loader>
							<v-skeleton-loader class="mx-auto mt-4" type="paragraph"></v-skeleton-loader>
						</div>
					</v-card>
				</div>

				<v-slide-y-transition group>
					<template v-if="!loading">
						<v-card
							class="shadow-blue-not-openned mb20"
							v-for="(check, index) in checking"
							:key="'suggestion-' + index"
							:data-suggestion-index="index"
						>
							<div class="suggestion-container" @mouseover="highlightError(index)" @mouseleave="initColorError(index)">
								<div class="align-items mb10">
									<v-icon class="iconError fs10" :class="check.issueType">fas fa-circle</v-icon>
									<span class="ml5 fs12 suggestion-topic"
										><strong v-if="check.issueType == 'typographical'">Typographie</strong>
										<strong v-else-if="check.issueType == 'misspelling'">Faute de frappe</strong>
										<strong v-else-if="check.issueType == 'style'">Style</strong>
										<strong v-else>Autre</strong>
									</span>
								</div>
								<div class="align-items ml15">
									<div v-if="check.replacements.length > 0" class="align-items container-chip row">
										<v-chip
											v-for="(remplacement, index) in limitArray(check.replacements || [])"
											:key="index"
											class="suggestion-chips"
											color="#15c39a"
											label
											@click="applyCorrection(check, remplacement.value)"
										>
											{{ remplacement.value }}
										</v-chip>
									</div>
									<p v-else><strong>Mot non reconnu</strong></p>
								</div>

								<div class="align-items ml15">
									<p>
										{{ check.message }}
									</p>
								</div>
							</div>
						</v-card>
					</template>
				</v-slide-y-transition>
			</v-col>
			<v-col md="8" cols="12">
				<v-alert v-if="error" type="error" class="mt20" prominent outlined>Une erreur s'est produite</v-alert>
			</v-col>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-else
		>
			<TextsSaved :formationId="formationProgress.formation._id" />
		</v-row>
		<v-row justify="center">
			<v-dialog v-model="noError" persistent max-width="430">
				<v-card>
					<v-card-title class="headline"
						>Bravo {{ formationProgress.user.first_name }}, aucune erreur détectée 🎉</v-card-title
					>
					<v-card-text>Nous n'avons pas détecté d'erreur dans ton texte.</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="#15c39a" text @click="closeNoErrorModal()">OKAY</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<ModalForRestrictedUser :testTrialData="formationProgress.formation.test_trial" modalName="assistantPage" />
		<v-snackbar v-model="snackbar" left color="#2dc7ae" :timeout="3000">
			<div class="d-flex justify-space-between align-center">
				<h3>Formation ajustée selon tes erreurs.</h3>
			</div>
		</v-snackbar>
		<v-snackbar v-model="snackbarErrorTextEmpty" left color="warning" :timeout="3000">
			<div class="d-flex justify-space-between align-center">
				<h3>Il faut d'abord écrire un texte.</h3>
			</div>
		</v-snackbar>
	</div>
</template>

<script>
import Axios from 'axios';
// import StarRating from 'vue-star-rating';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import TextsSaved from './TextsSaved';
import ModalForRestrictedUser from '../other/ModalForRestrictedUser/ModalForRestrictedUser';
import moment from 'moment';

export default {
	name: 'Correcteur',
	data() {
		return {
			autoCorrectEnabled: false, // Désactivé par défaut
			correctionTimeout: null,
			inactivityTimeout: null,
			countdownInterval: null,
			countdownSeconds: 0,
			lastCheckedText: '',
			isAutoChecking: false,
			text: '',
			checking: [],
			allTextError: [],
			loading: false,
			reformulatedText: '',
			loadingReformulation: false,
			noError: false,
			showRating: null,
			showAssistant: true,
			showConfirmation: false,
			btnExample: true,
			storageKeySendExample: 'sendAssistantExample',
			sectionReformulation: true,
			storageKeySendExampleReformulation: 'sectionReformulationExample',
			reformulatedBtn: true,
			snackbarCopied: false,
			axiosSource: Axios.CancelToken.source(),
			snackbarErrorTextEmpty: false,
			error: null, // Ajout de la propriété error
			snackbar: false, // Ajout de la propriété snackbar
		};
	},
	components: {
		// StarRating,
		TextsSaved,
		ModalForRestrictedUser,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		calculateScore() {
			if (!this.text || !this.text.trim()) return 0;

			// Nettoyer le HTML pour ne garder que le texte
			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = this.text;
			const textOnly = tempDiv.textContent || tempDiv.innerText;

			// Compter les vrais mots
			const wordCount = textOnly
				.trim()
				.split(/\s+/)
				.filter((word) => word.length > 0).length;
			if (wordCount === 0) return 0;

			const errorCount = this.checking.length;
			if (errorCount === 0) return 100;

			// Calcul du score
			const score = Math.max(0, 100 - (errorCount / wordCount) * 100);
			return Math.round(score);
		},
		getProgressColor() {
			const score = this.calculateScore;
			if (score >= 95) return '#4CAF50'; // Vert
			if (score >= 80) return '#FFC107'; // Orange
			return '#F44336'; // Rouge
		},
		getScoreMessage() {
			const score = this.calculateScore;
			if (score >= 95) return 'Extremely effective';
			if (score >= 80) return 'Very effective';
			if (score >= 60) return 'Moderately effective';
			return 'Needs improvement';
		},
		getScoreDescription() {
			const score = this.calculateScore;
			if (score >= 95) return "Votre texte est très bien écrit avec très peu ou pas d'erreurs.";
			if (score >= 80) return 'Votre texte est bien écrit mais contient quelques erreurs mineures.';
			if (score >= 60) return 'Votre texte nécessite quelques corrections pour être plus efficace.';
			return 'Votre texte nécessite des améliorations importantes.';
		},
		buttonText() {
			if (!this.autoCorrectEnabled) return 'Voir mes erreurs';
			if (this.countdownSeconds > 0) return `Correction dans ${this.countdownSeconds}s...`;
			return 'Désactiver auto-correction';
		},
	},
	mounted() {
		this.btnExample = !window.localStorage.getItem(this.storageKeySendExample);
		this.sectionReformulation = !window.localStorage.getItem(this.storageKeySendExampleReformulation);
	},
	beforeDestroy() {
		if (this.correctionTimeout) {
			clearTimeout(this.correctionTimeout);
		}
		if (this.inactivityTimeout) {
			clearTimeout(this.inactivityTimeout);
		}
		if (this.countdownInterval) {
			clearInterval(this.countdownInterval);
		}
		if (this.axiosSource) {
			this.axiosSource.cancel('Component destroyed');
		}
	},
	methods: {
		...mapActions('assistant', ['getReformulationFromAI']),

		hashText(text) {
			let hash = 0,
				i,
				chr;
			if (text.length === 0) return hash;
			for (i = 0; i < text.length; i++) {
				chr = text.charCodeAt(i);
				hash = (hash << 5) - hash + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash.toString();
		},

		refresh() {
			window.location.reload();
		},
		async reformulateText() {
			try {
				// Vérifie s'il y a du texte dans l'élément
				const textContent = this.$refs.text && this.$refs.text.innerText ? this.$refs.text.innerText.trim() : '';

				if (!textContent) {
					return (this.snackbarErrorTextEmpty = true);
				}

				// Ajoute le cookie pour masquer la section de l'exemple de reformulation
				const newCookieReformulation = { hideUntil: moment().add(100, 'days').unix() };
				window.localStorage.setItem(this.storageKeySendExampleReformulation, JSON.stringify(newCookieReformulation));

				this.loadingReformulation = true;

				// Prépare et envoie la question
				const promptReformulation =
					"Reformule et corrige le texte suivant (retire tous les elements HTML s'il y en a), ne pas ajouter de conseils, juste la phrase :";
				const question = promptReformulation + ' ' + textContent;

				const answer = await this.getReformulationFromAI({
					question,
					config: { cancelToken: this.axiosSource.token },
				});

				this.reformulatedText = answer;
			} catch (error) {
				console.error('Erreur lors de la reformulation:', error);
			} finally {
				this.loadingReformulation = false;

				if (process.env.VUE_APP_MIXPANEL === 'production') {
					this.$mixpanel.track('Reformulate text in assistant');
				}
			}
		},

		copyToClipboard(text) {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					this.snackbarCopied = true; // Affiche la notification
				})
				.catch((err) => {
					console.error('Erreur lors de la copie dans le presse-papier :', err);
				});
		},
		async submit() {
			if (this.loading) return;
			this.loading = true;
			let textForApi = '';

			const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
			window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

			try {
				this.checking = [];
				this.allTextError = [];
				let issueMessage = '';
				let textError = '';

				delete this.$http.defaults.headers.common['x-auth-token'];

				// Récupère le contenu HTML original et nettoie les styles inline
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = this.$refs.text.innerHTML;

				// Supprime tous les attributs style et les spans d'erreur précédents
				tempDiv.querySelectorAll('*').forEach((element) => {
					element.removeAttribute('style');
					if (
						element.tagName === 'SPAN' &&
						(element.classList.contains('errorText') || element.classList.contains('highlight'))
					) {
						// Remplace le span par son contenu texte
						element.replaceWith(element.textContent);
					}
				});

				const originalHtml = tempDiv.innerHTML;

				// Préserve la structure exacte du texte pour l'API
				textForApi = originalHtml
					.replace(/<div><br><\/div>/gi, '\n')
					.replace(/<div>/gi, '')
					.replace(/<\/div>/gi, '\n')
					.replace(/<br\s*\/?>/gi, '\n')
					.replace(/&nbsp;/g, ' ')
					.replace(/<\/?[^>]+(>|$)/g, '')
					.replace(/\n\s*\n/g, '\n\n')
					.replace(/([.!?])([\wÀ-ÿ])/g, '$1 $2')
					.trim();

				// Vérifie si le texte est vide
				if (!textForApi.trim()) {
					this.loading = false;
					return (this.snackbarErrorTextEmpty = true);
				}

				// Requête vers LanguageTool
				const requestCheck = await this.$http.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						text: textForApi,
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
					},
				});

				if (requestCheck.data.matches.length == 0) {
					this.noError = true;
					this.$confetti.start({
						particleCount: 100,
						spread: 70,
					});
					this.text = originalHtml;
					return;
				}

				// Si ce n'était pas une auto-correction, activer l'auto-correction pour la suite
				if (!this.autoCorrectEnabled) {
					this.autoCorrectEnabled = true;
				}

				// Copie du HTML original pour les modifications
				let correctedHtml = originalHtml;

				// Traitement des erreurs
				requestCheck.data.matches.forEach((element) => {
					issueMessage = element.rule.issueType === 'misspelling' ? element.rule.description : element.message;

					let positionInText = element.offset;
					textError = textForApi.substring(positionInText, positionInText + element.context.length);

					this.allTextError.push({
						text: textError,
						type: element.rule.issueType,
					});

					let filterBestRemplacement = element.replacements.filter((remplacement) => {
						if (remplacement.value.length > 2) {
							return remplacement.value.substring(0, 3) === textError.substring(0, 3);
						}
						return true;
					});

					this.checking.push({
						issueType: element.rule.issueType,
						replacements: filterBestRemplacement,
						message: issueMessage,
					});
				});

				// Application des corrections en préservant la structure HTML
				this.allTextError.forEach((element, index) => {
					const errorSpan = `<span class="errorText ${element.type}" id="textwrong-${index}">${element.text}</span>`;
					correctedHtml = correctedHtml.replace(element.text, errorSpan);
				});

				// Met à jour le texte avec les corrections tout en préservant la structure
				this.text = correctedHtml;

				// Ajoute les événements de hover
				this.$nextTick(() => {
					this.attachErrorEvents();
				});
			} catch (error) {
				console.error('Erreur lors de la correction:', error);
				this.error = true;
			} finally {
				this.loading = false;
				if (textForApi && textForApi.trim()) {
					this.snackbar = true;
				}
				this.showRating = true;
				this.btnExample = false;
				if (process.env.VUE_APP_MIXPANEL == 'production') {
					this.$mixpanel.track('Check text in assistant');
				}
			}
		},
		limitArray(array) {
			return array.slice(0, 5);
		},
		highlightError(indexTextError) {
			const errorSpan = document.getElementById('textwrong-' + indexTextError);
			if (errorSpan) {
				errorSpan.className = 'highlight';
				// Mettre en surbrillance la suggestion correspondante
				this.highlightSuggestion(indexTextError);
			}
		},

		initColorError(indexTextError) {
			const errorSpan = document.getElementById('textwrong-' + indexTextError);
			const error = this.checking[indexTextError];
			if (errorSpan && error) {
				errorSpan.className = 'errorText ' + error.issueType;
				// Désactiver la surbrillance de la suggestion
				this.unhighlightSuggestion(indexTextError);
			}
		},

		async registerText() {
			const ratingAssistant = await this.$store.dispatch('assistant/postRating', {
				formationId: this.formationProgress.formation._id,
				rating: this.rating,
				text: this.text,
			});
			if (ratingAssistant.status == 200) {
				this.showConfirmation = true;
			}
		},

		startAutoCheck() {
			clearTimeout(this.inactivityTimeout);
			clearTimeout(this.correctionTimeout);

			this.inactivityTimeout = setTimeout(() => {
				// Vérifier si le textarea est vide avant de démarrer le compte à rebours
				let currentText = '';
				if (this.$refs.text && this.$refs.text.innerText) {
					currentText = this.$refs.text.innerText.trim();
				}

				if (!currentText) {
					return;
				}

				// Démarrer le compte à rebours
				this.countdownSeconds = 3;
				this.countdownInterval = setInterval(() => {
					if (this.countdownSeconds > 0) {
						this.countdownSeconds--;
					} else {
						clearInterval(this.countdownInterval);
					}
				}, 1000);

				// Lancer la correction après 3 secondes
				this.correctionTimeout = setTimeout(() => {
					// Vérifier à nouveau si le texte est vide avant la correction
					const textToCheck = this.$refs.text && this.$refs.text.innerText ? this.$refs.text.innerText.trim() : '';

					if (textToCheck !== this.lastCheckedText && !this.loading) {
						this.submit();
						this.lastCheckedText = textToCheck;
					}
					this.countdownSeconds = 0;
				}, 3000);
			}, 500); // Délai d'inactivité de 500ms
		},

		toggleAutoCorrect() {
			this.autoCorrectEnabled = !this.autoCorrectEnabled;
			this.countdownSeconds = 0;

			// Nettoyer tous les timers
			if (this.correctionTimeout) {
				clearTimeout(this.correctionTimeout);
			}
			if (this.inactivityTimeout) {
				clearTimeout(this.inactivityTimeout);
			}
			if (this.countdownInterval) {
				clearInterval(this.countdownInterval);
			}

			if (!this.autoCorrectEnabled) {
				// Mode manuel activé
				this.lastCheckedText = '';
			} else {
				// Lancer une première vérification immédiate
				this.submit();
			}
		},

		attachErrorEvents() {
			const textDiv = this.$refs.text;
			if (!textDiv) return;

			const errorSpans = textDiv.querySelectorAll('[id^="textwrong-"]');
			errorSpans.forEach((span) => {
				const errorIndex = parseInt(span.id.replace('textwrong-', ''));

				// Retirer les anciens événements pour éviter les doublons
				const oldClickHandler = span._clickHandler;
				const oldMouseoverHandler = span._mouseoverHandler;
				const oldMouseleaveHandler = span._mouseleaveHandler;

				if (oldClickHandler) span.removeEventListener('click', oldClickHandler);
				if (oldMouseoverHandler) span.removeEventListener('mouseover', oldMouseoverHandler);
				if (oldMouseleaveHandler) span.removeEventListener('mouseleave', oldMouseleaveHandler);

				// Créer les nouveaux handlers
				const clickHandler = (e) => {
					e.preventDefault();
					const suggestionCard = document.querySelector(`[data-suggestion-index="${errorIndex}"]`);
					if (suggestionCard) {
						suggestionCard.scrollIntoView({
							behavior: 'smooth',
							block: 'center',
						});
						suggestionCard.classList.add('suggestion-flash');
						setTimeout(() => {
							suggestionCard.classList.remove('suggestion-flash');
						}, 1000);
					}
				};

				const mouseoverHandler = () => this.highlightSuggestion(errorIndex);
				const mouseleaveHandler = () => this.unhighlightSuggestion(errorIndex);

				// Sauvegarder les références des handlers
				span._clickHandler = clickHandler;
				span._mouseoverHandler = mouseoverHandler;
				span._mouseleaveHandler = mouseleaveHandler;

				// Ajouter les nouveaux événements
				span.addEventListener('click', clickHandler);
				span.addEventListener('mouseover', mouseoverHandler);
				span.addEventListener('mouseleave', mouseleaveHandler);
			});
		},

		applyCorrection(error, correction) {
			const textDiv = this.$refs.text;
			if (!textDiv) return;

			// Trouver l'index de l'erreur dans le tableau checking
			const errorIndex = this.checking.indexOf(error);
			if (errorIndex === -1) return;

			// Trouver le span d'erreur correspondant
			const errorSpan = document.getElementById(`textwrong-${errorIndex}`);
			if (!errorSpan) return;

			// Créer un nouveau span pour la correction
			const correctedSpan = document.createElement('span');
			correctedSpan.textContent = correction;
			correctedSpan.className = 'corrected-text';

			// Sauvegarder la position du curseur
			const selection = window.getSelection();
			const range = selection.getRangeAt(0);
			const isInError = errorSpan.contains(range.startContainer) || errorSpan.contains(range.endContainer);
			const startOffset = range.startOffset;
			const endOffset = range.endOffset;

			// Remplacer l'ancien span par le nouveau
			errorSpan.replaceWith(correctedSpan);

			// Restaurer la position du curseur si nécessaire
			if (isInError) {
				const newRange = document.createRange();
				newRange.setStart(correctedSpan.firstChild, Math.min(startOffset, correctedSpan.textContent.length));
				newRange.setEnd(correctedSpan.firstChild, Math.min(endOffset, correctedSpan.textContent.length));
				selection.removeAllRanges();
				selection.addRange(newRange);
			}

			// Mettre à jour la liste des erreurs
			this.checking.splice(errorIndex, 1);
			this.allTextError.splice(errorIndex, 1);

			// Mettre à jour tous les spans d'erreur en commençant par la fin
			const errorSpans = Array.from(textDiv.querySelectorAll('.errorText'));
			for (let i = errorSpans.length - 1; i >= 0; i--) {
				const span = errorSpans[i];
				const currentIndex = parseInt(span.id.replace('textwrong-', ''));

				// Si l'index actuel est supérieur à l'index de l'erreur corrigée,
				// on décale d'un rang vers la droite
				if (currentIndex > errorIndex) {
					span.id = `textwrong-${currentIndex - 1}`;
				}

				// Réinitialiser la classe avec le bon type d'erreur
				const error = this.checking[currentIndex > errorIndex ? currentIndex - 1 : currentIndex];
				if (error) {
					span.className = 'errorText ' + error.issueType;
				}
			}

			// Réattacher les événements avec les nouveaux indices
			this.attachErrorEvents();

			// Si c'était la dernière erreur, désactiver temporairement l'auto-correction
			if (this.checking.length === 0) {
				const wasAutoCorrectEnabled = this.autoCorrectEnabled;
				this.autoCorrectEnabled = false;

				// Réactiver l'auto-correction après un court délai
				setTimeout(() => {
					this.autoCorrectEnabled = wasAutoCorrectEnabled;
					// Lancer une dernière vérification
					this.submit();
				}, 500);
			}
		},
		cleanContent() {
			if (this.$refs.text) {
				this.$refs.text.innerHTML = '';
				this.text = '';
				this.checking = [];
				this.allTextError = [];
				this.noError = false;
				this.reformulatedText = '';
				this.autoCorrectEnabled = false;
			}
		},
		handlePaste(e) {
			e.preventDefault();

			// Récupérer le texte brut du presse-papiers
			const text = e.clipboardData.getData('text/plain');

			// Nettoyer le texte tout en préservant la structure
			const cleanedText = text
				.replace(/[\u200B-\u200D\uFEFF]/g, '') // Supprime les caractères invisibles
				.replace(/\r\n/g, '\n') // Normalise les retours à la ligne
				.replace(/\r/g, '\n') // Convertit les retours chariot en sauts de ligne
				.replace(/&nbsp;/g, ' ') // Remplace les espaces insécables par des espaces normaux
				.replace(/<\/?[^>]+(>|$)/g, '') // Supprime les balises HTML
				.replace(/\n\s*\n/g, '\n\n') // Limite les sauts de ligne consécutifs à 2 maximum
				.replace(/([.!?])([\wÀ-ÿ])/g, '$1 $2') // Ajoute un espace après les ponctuations
				.trim();

			// Créer le HTML avec les sauts de ligne préservés
			const formattedHtml = cleanedText
				.split('\n')
				.map((line) => `<div>${line || '<br>'}</div>`)
				.join('');

			// Insérer le texte formatté
			document.execCommand('insertHTML', false, formattedHtml);

			// Mettre à jour la variable text
			this.text = this.$refs.text.innerHTML;
		},
		highlightSuggestion(index) {
			const suggestionCard = document.querySelector(`[data-suggestion-index="${index}"]`);
			if (suggestionCard) {
				suggestionCard.classList.add('suggestion-highlighted');
			}
		},

		unhighlightSuggestion(index) {
			const suggestionCard = document.querySelector(`[data-suggestion-index="${index}"]`);
			if (suggestionCard) {
				suggestionCard.classList.remove('suggestion-highlighted');
			}
		},
		closeNoErrorModal() {
			this.noError = false;
			this.$confetti.stop();
		},
		async submitExample() {
			// Prevent double click
			if (this.loading) return;

			// Example text
			const exampleText =
				"Écrivez ou collez votre texte ici pour le faire vérifier en continue. Les erreurs seront soulignés de différentes couleurs : les erreurs d'orthografe en rouge et les erreurs grammaticaux en jaune. Les problèmes de style, comme par exemple les pléonasmes, seront marqués en bleu dans vos textes. Le saviez vous ? OrthographIQ vous propose des synonymes lorsque vous double-cliquez sur un mot . Découvrez l'intégralité de ses fonctionnalités, parfoi inattendues, comme la vérification des date. Par exemple, le mercredi 28 août 2024 était en fait un vendredi !";

			// Insert text in editor
			if (this.$refs.text) {
				this.$refs.text.innerHTML = exampleText;
				this.text = exampleText;
			}

			try {
				// Hide example button for 100 days
				const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
				window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

				// Launch correction
				await this.submit();

				// Wait a bit then launch reformulation
				setTimeout(() => {
					this.reformulateText();
				}, 2000);
			} catch (error) {
				console.error('Error in submitExample:', error);
			}
		},
	},
};
</script>

<style lang="scss">
.main-assistant-header-container {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 0px 12px 12px 12px;
}

.progress-bar-container {
	width: 300px;
}

.score-display {
	max-width: 400px;
	margin: 0 auto;
}

.score-index {
	display: flex;
	align-items: right;
	justify-content: flex-end;
	margin-bottom: 10px;
}

.score-value {
	font-size: 1.2rem;
	font-weight: bold;
	color: black;
	line-height: 1;
}

.progress-gradient {
	height: 8px;
	background: linear-gradient(to right, #ffd700, #ff6b6b, #9370db, #4caf50);
	border-radius: 4px;
	position: relative;
}

.progress-marker {
	position: absolute;
	top: -6px;
	width: 20px;
	height: 20px;
	background: black;
	border: 2px solid white;
	border-radius: 50%;
	transform: translateX(-50%);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fullAssistantContainer {
	margin-top: 20px;
}
.assistant-link {
	cursor: pointer;
	transition: color 0.5s;
}
.assistant-link:hover {
	color: black !important;
}
.searching-image-container {
	text-align: center;
}
.searching-image {
	max-width: 100%;
}
.container-textarea {
	position: -webkit-sticky;
	position: sticky;
	top: 20px;
	.divEditionText {
		height: 20rem;
		&:focus {
			outline: none;
		}
	}
}
.suggestion-topic {
	text-transform: uppercase;
}
.assistant-icon {
	width: 20%;
	margin-right: 10px;
}
.suggestion-container {
	padding: 20px 20px 20px 20px;
}
.suggestion-container:hover {
	background-color: rgba(255, 0, 0, 0.06);
}
.container-chip {
	margin-bottom: 1.5rem;
}
.suggestion-chips {
	margin-top: 0.5rem;
	cursor: pointer;
	color: white !important;
	font-weight: bold;
	margin-left: 12px;
}
.menuSelected {
	color: grey !important;
	// border-bottom: 1px solid grey;
}
.align-items {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.assistant-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.v-input__slot {
	position: initial !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
	box-shadow: initial;
	-webkit-box-shadow: initial;
}
.errorText {
	&.uncategorized {
		background: rgb(201, 205, 255);
	}
	&.misspelling {
		background: rgb(255 228 201);
	}
	&.style {
		background: #e3c977;
	}
	&.typographical {
		background: rgba(255, 0, 0, 0.2);
	}
}
.iconError {
	&.uncategorized {
		color: rgb(201, 205, 255);
	}
	&.misspelling {
		color: rgb(255 228 201);
	}
	&.style {
		color: #e3c977;
	}
	&.typographical {
		color: rgba(255, 0, 0, 0.2);
	}
}
.highlight {
	background-color: rgba(255, 0, 0, 0.1);
	color: #ff4444;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	padding: initial;
}
.v-input {
	font-size: 20px !important;
}

[id^='textwrong-'] {
	border-bottom: 2px solid #ff4444;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		background-color: rgba(255, 0, 0, 0.1);
		color: #ff4444;
	}
}

// Style pour les suggestions
.suggestion-highlighted {
	background-color: rgba(255, 68, 68, 0.1);
	// border-left: 3px solid #ff4444;
	transition: all 0.2s ease;

	.suggestion-container {
		background-color: rgba(255, 68, 68, 0.1);
	}

	.suggestion-topic {
		color: #ff4444;
	}

	.suggestion-chips {
		transform: scale(1.02);
		transition: transform 0.2s ease;
	}

	.iconError {
		transform: scale(1.1);
		transition: transform 0.2s ease;
	}
}

.shadow-blue-not-openned {
	transition: all 0.2s ease;
}

.score-index {
	display: flex;
	align-items: right;
	justify-content: flex-end;
	margin-bottom: 10px;
}

.score-title {
	font-size: 2.5rem;
	color: #666;
	font-weight: 400;
	margin-bottom: 40px;
	text-align: left;
}

.corrected-text {
	transition: background-color 0.3s ease;
	background-color: rgba(21, 195, 154, 0.1);
	&:hover {
		background-color: rgba(21, 195, 154, 0.2);
	}
}
.suggestion-flash {
	animation: flash 1s ease;
}

@keyframes flash {
	0% {
		background-color: rgba(255, 68, 68, 0.1);
	}
	50% {
		background-color: rgba(255, 68, 68, 0.3);
	}
	100% {
		background-color: rgba(255, 68, 68, 0.1);
	}
}

@media screen and (max-width: 960px) {
	.fullAssistantContainer {
		margin-right: 0; // Override vuetify .row that makes page overflow y
		margin-left: 0;
	}
	.main-assistant-header-container {
		display: flex;
		align-items: left;
		flex-direction: column;
		justify-content: left;
		width: 100%;
		padding: 0px 12px 12px 12px;
	}
	.actionBtnContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.actionBtnAssistant {
		width: 100%;
		margin-bottom: 10px;
	}
	.searching-image-container {
		display: none;
	}
	// .ratingStars {
	// 	display: none !important;
	// }
}
</style>
